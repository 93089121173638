import axios from "axios";
import { config } from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

class ActiveRuleService {
  getAllActiveRules(url, pageInform) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
      params: {
        currentPage: pageInform.currentPage,
        perPage: pageInform.perPage,
      },
    });
  }
  updateActiveRules(url, setting) {
    return axios.put(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
  deleteActiveRules(url) {
    return axios.delete(API_URL + url, {
      headers: authHeader(),
    });
  }
}
export default new ActiveRuleService();
