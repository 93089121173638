<template>
  <Spinner v-show="!loaded"></Spinner>
  <div>
    <b-modal
      v-model="enableDelete"
      size="sm"
      title="Delete Active Rule"
      hide-backdrop
      ok-title="Delete"
      @ok="deleteActiveRule(singleDeleteData.id)">
      Are you sure you want to delete this active rule?
    </b-modal>
  </div>
  <b-row class="p-3" v-show="setting.length > 0">
    <div class="col-8 w-100 table-responsive-lg">
      <table class="table table-dark">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Destination Prefix</th>
            <th scope="col">Block all UDP</th>
            <th scope="col">Block all TCP</th>
            <th scope="col">Block all ICMP</th>
            <th scope="col">Block Source UDP</th>
            <th scope="col">Block Source TCP</th>
            <th scope="col">Block Destination UDP</th>
            <th scope="col">Block Destination TCP</th>
            <th scope="col">Block Packet Lengths</th>
            <th scope="col">Time Limit</th>
            <th scope="col">Created</th>
            <th scope="col">Updated At</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="element in setting" :key="element">
            <td class="text-white" scope="row">{{ element.id }}</td>
            <td class="text-white" scope="row">
              {{ element.destinationPrefix }}
            </td>
            <td class="text-white">
              {{ element.udpAll == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.tcpAll == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.icmpAll == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.udpSource == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.tcpSource == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.udpDest == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.tcpDest == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.packetLength == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.timeLimit }}
            </td>
            <td class="text-white" v-if="element">
              {{ getDateString(new Date(element.createdAt).toISOString()) }}
            </td>
            <td class="text-white" v-if="element">
              {{ getDateString(new Date(element.updatedAt).toISOString()) }}
            </td>
            <td class="text-white">
              <b-button
                variant="primary"
                class="mr-3"
                size="sm"
                :to="'/active-rules/edit/' + element.id + '?page=' + this.currentPage">
                <font-awesome-icon icon="pencil" />
              </b-button>
              &nbsp;
              <b-button
                variant="danger"
                class="mr-2"
                size="sm"
                @click="enableDeletion(element.id)">
                <font-awesome-icon icon="trash" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-row>

  <b-row class="text-center m-3 d-block" v-if="setting.length">
    <b-pagination
      v-model="currentPage"
      align="center"
      :total-rows="totalCount"
      :per-page="perPage"
      aria-controls="statistics-table"
    ></b-pagination>
  </b-row>

  <div v-show="setting.length == 0 && loaded">
    <h1 class="text-white text-center mt-3">No active rules</h1>
  </div>
</template>
<script>
  import ActiveRuleService from "../../services/active-rule.service";
  import "vue3-toastify/dist/index.css";
  import { notify } from "../../helpers/index";
  import { DateTime } from "luxon";
  import Spinner from "../../components/spinner/spinner.vue";

  export default {
    name: "ActiveRule",
    components: {
      Spinner,
    },
    data: function () {
      return {
        setting: [],
        currentPage: 1,
        perPage: 10,
        totalCount: 0,
        fields: [
          { key: "id", label: "ID" },
          { key: "destIP", label: "Destination IP" },
          { key: "udpAll", label: "Block all UDP" },
          { key: "tcpAll", label: "Block all TCP" },
          { key: "icmpAll", label: "Block all ICMP" },
          { key: "udpSource", label: "Block Source UDP" },
          { key: "tcpSource", label: "Block Source TCP" },
          { key: "udpDest", label: "Block Destination UDP" },
          { key: "tcpDest", label: "Block Destination TCP" },
          { key: "packetLength", label: "Block Packet Lengths" },
          { key: "timeLimit", label: "Time Limit" },
          { key: "createdAt", label: "Created" },
          { key: "updatedAt", label: "Updated" },
          { key: "actions", label: "Actions" },
        ],
        enableDelete: false,
        singleDeleteData: {},
        loaded: false,
      };
    },
    watch: {
      currentPage(newPage) {
        this.getAllActiveRules(newPage);
        window.history.pushState(null, null, `?page=${newPage}`);
      },
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
    },
    mounted() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const pageNum = urlParams.get('page');
      if(pageNum) this.currentPage = pageNum;
      this.getAllActiveRules(this.currentPage);
    },
    methods: {
      enableDeletion(id) {
        (this.enableDelete = true),
          (this.singleDeleteData = {
            id: id,
          });
      },
      logOut() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      },
      async getAllActiveRules(page = 0) {
        const url = "active-rules";
        
        try {
          const reply = await ActiveRuleService.getAllActiveRules(url, {
            currentPage: page,
            perPage: this.perPage,
          });
          if (reply.status == 200 && reply.data) {
            this.totalCount = reply?.data?.totalCount;
            this.currentPage = reply?.data?.currentPage;
            this.setting = reply?.data?.data;
            this.loaded = true;
          }
        }catch {
          notify("Session expired", "error");
          this.logOut();
        }
      },
      async deleteActiveRule(id) {
        const url = `active-rules/${id}`;
        const response = await ActiveRuleService.deleteActiveRules(url);
        if (response.status == 200 && response.data) {
          notify("Active Rule Deleted Successfully");
          this.getAllActiveRules(this.currentPage);
        }
      },
      getDateString(date) {
        return DateTime.fromISO(date).toFormat("yyyy-M-d");
      },
      capitalize(string) {
        string.charAt(0).toUpperCase() + string.slice(1);
      },
    },
  };
</script>
<style scoped>
  .bg-secondary {
    background-color: #f7fafc !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    scale: 0.7;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .statistics-table {
    max-width: 100vw;
    overflow: auto;
  }
</style>
